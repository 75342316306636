import { useLanguageContext } from "../context/language";

const dictionary = {
  fi: {
    finnish: "Suomeksi",
    english: "Englanniksi",
    icon: "Kuvake",
    restaurant_id: "Ravintolan tunniste",
    input_restaurant_id: "Kirjoita alle ravintolan tunniste:",
    restaurant_not_found: "Ravintolaa ei löytynyt",
    to_order: "Tilaamaan",
    return: "Palaa",
    waiting_for_accept: "Odotetaan tilauksen hyväksyntää",
    order_accepted: "Tilaus hyväksytty, odotetaan valmistumista",
    order_ready: "Tilauksesi on nyt noudettavissa!",
    deliveryId: "Tilaustunnus",
    for_restaurant_owner: "Ravintoloitsijalle",
    mark_received: "Merkitse vastaanotetuksi",
    login: "Kirjautuminen",
    username_or_email: "Käyttäjätunnus / Sähköposti",
    username: "Käyttäjätunnus",
    email: "Sähköposti",
    password: "Salasana",
    name: "Nimi",
    password_again: "Salasana uudestaan",
    remember_me: "Muista minut",
    sign_in: "Kirjaudu sisään",
    forgot_password: "Unohdin salasanani",
    create_account: "Luo uusi käyttäjä",
    sign_up: "Luo käyttäjä",
    already_have_an_account: "Minulla on jo käyttäjä, kirjaudu sisään",
    invalid_credentials: "Virheellinen käyttäjätunnus tai salasana",
    invalid_email: "Virheellinen sähköpostiosoite",
    email_already_in_use: "Sähköpostiosoite on jo käytössä",
    username_already_in_use: "Käyttäjänimi on jo käytössä",
    password_requirements: "Salasanan pitää olla vähintään 8 merkkiä ja sisältää yksi numero",
    passwords_dont_match: "Salasanat eivät täsmää",
    missing_fields: "Täytä puuttuvat tiedot",
    unauthorized: "Et ole kirjautunut sisään",

    create_organisation: "Luo uusi yritys",
    edit_organisation: "Muokkaa yrityksen tietoja",
    join_organisation: "Liity olemassa olevaan yritykseen",
    organisation_name: "Yrityksen nimi",
    business_id: "Y-tunnus",
    business_id_already_in_use: "Y-tunnus on jo käytössä",
    business_id_invalid: "Virheellinen Y-tunnus, Y-tunnus on muotoa 1234567-8",
    counter: "Tilauspiste",
    counters: "Tilauspisteet (esim. baaritiski)",
    add_counter: "Lisää tilauspiste",
    close_counter: "Sulje tilauspiste",
    bar_name: "Baarin nimi (näkyy asiakkaalle)",
    counter_name: "Tilauspisteen nimi (jos useampi baarissa, näkyy asiakkaalle)",
    counter_helper: "Käytä tätä mikäli tiskejä / tilauspisteitä on useampi, tiskin nimi voisi olla esim. karaokepuoli, terassi, alakerta tms.",
    add_company: "Lisää yritys",
    passcode_helper: "Käyttäjät kirjautuvat baarisi sivulle tällä pääsykoodilla. Pääsykoodin täytyy olla 3-5 kirjainta pitkä.",
    passcode: "Tilauspisteen pääsykoodi",
    passcode_invalid: "Virheellinen tilauspisteen pääsykoodi, pääsykoodin pitää olla 3-5 merkkiä pitkä ja sisältää vain kirjaimia",
    could_not_generate_unique_code: "Tilauspisteen pääsykoodia ei voitu luoda",
    passcode_already_in_use: "Tilauspisteen pääsykoodi on jo käytössä",
    add_users_to_organisation: "Lisää muita käyttäjiä yritykselle",
    add_other_emails: "Kirjoita tähän muiden lisättävien käyttäjien sähköpostit",
    add_user: "Lisää käyttäjä",

    product_name: "Tuotteen nimi",
    price: "Tuotteen hinta",
    pre_tax_price: "Veroton hinta",
    vat: "ALV %",
    split_tax: "Käytä kahta eri verokantaa",
    split_tax_vat: "Toisen verokannan ALV %",
    split_tax_description: "Kuvaus kuitissa esim. mikseri",
    split_tax_price: "Toisen verokannan hinta",
    receipt_example: "Esimerkki kuitista",
    optional: "(Valinnainen)",
    update_product: "Päivitä tuote",
    create_product: "Lisää tuote",
    delete: "Poista",
    image_bank_select: "Valitse kuva pankistamme",
    or_upload: "Tai lisää oma kuvasi",
    drag_and_drop_image_here: "Vedä ja pudota kuva tähän",

    order_view: "Tilaukset",
    product_view: "Tuotteet",
    organisation_view: "Yritys",
    counter_view: "Tilauspisteet",
    user_view: "Asetukset",

    beer_generic: "Olut (yleinen)",
    cider_generic: "Siideri (yleinen)",
    long_drink_generic: "Lonkero (yleinen)",
    cranberry_long_drink_generic: "Karpalolonkero (yleinen)",
    wheat_beer_generic: "Vehnäolut (yleinen)",
    red_wine_generic: "Punaviini (yleinen)",
    white_wine_generic: "Valkoviini (yleinen)",
    champagne_generic: "Kuohuviini (yleinen)",
    karhu: "Karhu",
    sandels: "Sandels",
    karjala: "Karjala",
    a_le_coq: "A. Le Coq",
    kronenburg_blanc: "Kronenburg Blanc",
    koff: "Koff",
    olvi: "Olvi",
    aura: "Aura",
    somersby: "Somersby",
    happy_joe: "Happy Joe",
    fizz: "Fizz",
    upcider: "Upcider",
    crowmore: "Crowmore",
    hartwall_original: "Hartwall Original",
    hartwall_original_cranberry: "Hartwall Original Karpalo",
    a_le_coq_long_drink: "A. Le Coq Long Drink",

    open_counter: "Avaa tilauspiste",

    create_tag: "Lisää kategoria",
    tag_name: "Lisää",
    view_tags: "Näytä kategoriat",
    tag_info: "Lisää kategorian nimi",
    all_tags: "Kaikki kategoriat",
    editing_tag: "Muokkaa kategoriaa",
    tags: "Kategoriat",
    product_counters: "Tilauspisteet",
    no_category: "Ei kategoriaa",
    no_counters: "Ei tilauspisteitä",

    copy_products_from: "Kopioi tuotteet toiselta tilauspisteeltä",
    choose_products_to_copy: "Valitse tilauspiste josta kopioit tuotteet",
    copy_products: "Kopioi tuotteet",

    tables: "Pöydät",
    table: "Pöytä:",
    add_table: "Lisää pöytä",
    no_tables: "Ei pöytiä",
    enable_tables: "Ota pöydät käyttöön",
    edit_table: "Muokkaat pöytää:",
    table_info: "Pöydän lisätiedot",
    edit: "Muokkaa",
    table_code: "Pöydän koodi",
    table_code_helper: "Pöydän koodi on numero jonka asiakas näkee pöydässä. Koodin täytyy olla välillä 1-1000",
    table_info_helper: "Pöydän lisätiedot näkyvät vain organisaation jäsenille, 0-50 merkkiä",
    no_table: "Ei pöytää",
    choose: "Valitse",
    choose_table: "Valitse pöytä",
    activate_table_service: "Ota pöytäpalvelu käyttöön",
    no_table_selected: "Ei pöytää valittuna",

    closed: "Tilauspalvelu on suljettu. Tervetuloa myöhemmin uudelleen",
    refresh: "Päivitä",
    loading: "Ladataan...",
    error: "Virhe",
    loading_error: "Latausvirhe",

    settings: "Asetukset",
    change_password: "Vaihda salasana",
    old_password: "Vanha salasana",
    new_password: "Uusi salasana",
    save: "Tallenna muutokset",
    settings_saved: "Asetukset tallennettu",
    settings_not_saved: "Asetuksia ei voitu tallentaa",
    wrong_old_password: "Vanha salasana väärin",
    same_password: "Uusi salasana ei voi olla sama kuin vanha salasana",
    continue: "Jatka",
    wrong_password: "Väärä salasana",
    passwords_dont_match: "Salasanat eivät täsmää",
    first_new_password: "Uusi salasana",
    second_new_password: "Uusi salasana uudelleen",
    no_database_connection: "Tietokantaan ei saada yhteyttä",

    to_shopping_cart: "Siirry ostoskoriin",
    shopping_cart: "Ostoskori",
    shopping_cart_empty: "Ostoskori on tyhjä",
    shopping_cart_total: "Yhteensä:",
    empty_shopping_cart: "Tyhjennä ostoskori",
    total_products: "Tuotteita yhteensä:",

    order_already_delivered: "Tilaus merkitty toimitetuksi",
    order_not_found: "Tilausta ei löytynyt",
    order_not_paid: "Tilausta ei maksettu",
    view_product_details: "Tarkastele",
    shopping_list_empty: "Ostoslista on tyhjä",
    
    to_checkout: "Kassalle",
    checkout: "Kassa",
    items: "Ostokset",
    delivery: "Toimitus",
    to_table_nr: "Pöytään nro:",
    self_fetch: "Noudan tiskiltä",
    to_pay: "Maksamaan",
    want_receipt: "Haluan kuitin",
    no_receipt: "En halua kuittia",
    email_for_receipt: "Sähköpostiosoite kuittia varten",
    receipt: "Kuitti",
    non_valid_email: "Sähköpostiosoitteesi ei ole pätevä",
    empty_email: "Sähköpostiosoite puuttuu",
    notification_options: "Muuta ilmoitusasetuksia",
    total: "yhteensä",

    notification_options_title: "Ilmoitusasetukset",
    browser_notification: "Ota selainilmoitukset käyttöön",
    skip_notification: "Ohita",
    stop_notifications: "Lopeta ilmoitukset",
    notification_created: "Ilmoitus luotu",
    notifications_must_be_accepted: "Ilmoitukset täytyy hyväksyä niiden näyttämiseksi",
    notification_plead_0: "Älä missaa tilaustasi!",
    notification_plead_1: "Ota ilmoitukset käyttöön.",
    notification_plead_2: "Älä huoli. Käytämme ilmoituksia vain valmiin tilauksesi ilmoittamiseen.",
    
    something_went_wrong: "Jotain meni pieleen",
    thanks_for_order: "Kiitos tilauksesta!",
    drinks_are_ready: "Juomat ovat valmiit!",
    ordered_drinks_soon_ready: "Juomat ovat pian valmiit",
    order_code_is: "Tilauskoodisi on:",
    add_phone: "Lisää puhelinnumerosi",
    confirm: "Vahvista",
    cancel: "Peruuta",
    logout: "Kirjaudu ulos",
    password_reasoning: "Syötä ensin salasanasi että pääset muokkaamaan tietojasi",
    queue_status: "Jonon tilanne",
    ready: "Valmis!",
    slide_to_mark_received: "Vedä merkitäksesi tilaus vastaanotetuksi",
    history: "Tilaushistoria",
    new_orders: "Uudet tilaukset",
    fetchable: "Noudettavissa",
    variants: "Variantit (esim. Juoman koko tai GT:n Gin-merkki)",
    variants_short: "Variantit",
    variant: "Variantin otsikko",
    variants_listing: "Variantit",
    no_variants: "Ei variantteja",
    option_name: "Vaihtoehdon nimi",
    options: "Vaihtoehdot",
    description: "Kuvaus (valinnainen)",
    price_difference: "Hintaero (esim. -0.5)",
    calculated_automatically: "Lasketaan automaattisesti",
    closing_times: "Automaattiset sulkeutumisajat",
    closing_times_desc: 'Syötä aika viiden minuutin tarkkuudella muodossa hh:mm, järjestelmä ottaa päivän vaihtumisen huomioon, esim. 23:00 sulkee tilauspisteen klo 23:00 ja 01:00 sulkee tilauspisteen klo 01:00 seuraavana päivänä (päivä "vaihtuu" klo 10:00). Voit myös jättää kentän tyhjäksi, jolloin tilauspiste ei sulkeudu automaattisesti.',
    additional_info: "Lisätiedot",
    mon: "ma",
    tue: "ti",
    wed: "ke",
    thu: "to",
    fri: "pe",
    sat: "la",
    sun: "su",
    invalid_time_format: "!",

    invalid_user_input: "Virheellinen käyttäjän syöte",
    internal_server_error: "Palvelimen sisäinen virhe",

    back_to_all_categories: "Takaisin kaikkiin kategorioihin",
    counter_closed: "Tilauspiste suljettu",
    back: "Takaisin",
    size: "Koko",
    alcohol_content: "Alkoholipitoisuus",
    tax: "ALV %",
    creating_charge_failed: "Maksun luominen epäonnistui",
    payment_failed: "Maksu epäonnistui",
    payment_update_error: "Maksutoimenpiteen tiedot eivät päivittyneet. Ota yhteyttä ylläpitoon.",
    payment_maintenance_break: "Maksu epäonnistui huoltokatkon vuoksi",
    creating_charge_failed: "Maksupalveluun yhdistäminen epäonnistui",
    unknown_error: "Tuntematon virhe",
    last_call: "Pilkku",
    five_min_accuracy: "Sulkeutumisaika muutettu 5 minuutin tarkkuuteen",
    change_visibility: "Muuta tuotteen näkyvyyttä",
    orders: "Tilaukset",
    notification_package_object: {
      order_title: "Tilauksesi on valmis!",
      order_body_0: "Tilauksesi ",
      order_body_1: " on noudettavissa.",
      order_body_delivery: " on matkalla pöytään "
    },
    search: "Haku",
    reorganize: "Järjestä uudelleen",
    can_also_dragdrop: "Voit myös raahata ja pudottaa tuotteita niiden järjestelemiseksi",
    can_also_dragdrop_tags: "Voit raahata ja pudottaa kategorioita niiden järjestelemiseksi",
    show_all: "Näytä kaikki",
    different_browser_warning: "Huomio! Havaitsimme että avasit jonkun muun tekemän tilauksen. Tämä tilaus on tehty eri selaimella tai eri laitteella. Jos epäilet virhettä keskustele asiasta baarin työntekijän kanssa.",
    day: "Päivä",
    time: "klo",
    refund: "Hyvitä",
    refunded: "Hyvitetty",
  },
  en: {
    finnish: "Finnish",
    english: "English",
    icon: "Icon",
    restaurant_id: "Restaurant ID",
    input_restaurant_id: "Input restaurant ID below:",
    restaurant_not_found: "Restaurant not found",
    to_order: "To order",
    return: "Return",
    waiting_for_accept: "Waiting for order to be accepted",
    order_accepted: "Order accepted, waiting for it to be ready",
    order_ready: "Your order is now ready for pickup!",
    deliveryId: "Order ID",
    for_restaurant_owner: "For restaurant owner",
    mark_received: "Mark received",

    login: "Login",
    username_or_email: "Username / Email",
    username: "Username",
    email: "Email",
    password: "Password",
    name: "Name",
    password_again: "Password again",
    remember_me: "Remember me",
    sign_in: "Sign in",
    forgot_password: "Forgot password",
    create_account: "Create account",
    sign_up: "Sign up",
    already_have_an_account: "Already have an account, sign in",
    invalid_credentials: "Invalid username or password",
    invalid_email: "Invalid email",
    email_already_in_use: "Email already in use",
    username_already_in_use: "Username already in use",
    password_requirements: "Password must be at least 8 characters long and contain one number",
    passwords_dont_match: "Passwords don't match",
    missing_fields: "Fill in missing fields",
    unauthorized: "You are not logged in",
    return: "Return",

    create_organisation: "Create new organisation",
    edit_organisation: "Edit organisation",
    join_organisation: "Join existing organisation",
    organisation_name: "Organisation name",
    business_id: "Business ID",
    business_id_already_in_use: "Business ID already in use",
    business_id_invalid: "Invalid business ID, business ID should be in format 1234567-8",
    counter: "Counter",
    counters: "Counters",
    add_counter: "Add counter",
    close_counter: "Close counter",
    bar_name: "Bar name (shown to customer)",
    counter_name: "Counter name (if more than one per bar, shown to customer)",
    counter_helper: "Use this if you have multiple counters, for example karaoke side, terrace, downstairs etc.",
    add_company: "Add company",
    passcode_helper: "Users will log in to your bar with this passcode. Passcode must be 3-5 characters long.",

    passcode: "Passcode",
    passcode_invalid: "Invalid passcode, passcode must be 3-5 characters long and contain only letters",
    could_not_generate_unique_code: "Could not generate unique passcode",
    passcode_already_in_use: "Passcode already in use",
    add_users_to_organisation: "Add other users to organisation",
    add_other_emails: "Write other users emails here",

    add_user: "Add user",

    product_name: "Product name",
    price: "Price",
    pre_tax_price: "Pre-tax price",
    vat: "VAT %",
    split_tax: "Use two different tax rates",
    split_tax_description: "Title for the receipt eg. mixer",
    split_tax_vat: "Different tax rate VAT %",
    split_tax_price: "Different tax rate price",
    receipt_example: "Receipt example",
    optional: "(Optional)",
    update_product: "Update product",
    create_product: "Create product",
    delete: "Delete",
    image_bank_select: "Select image from our image bank",
    or_upload: "Or upload your own image",
    drag_and_drop_image_here: "Drag and drop image here",

    order_view: "Order view",
    product_view: "Products",

    organisation_view: "Organisation",
    counter_view: "Counters",
    user_view: "Settings",

    beer_generic: "Beer (generic)",
    cider_generic: "Cider (generic)",
    long_drink_generic: "Long drink (generic)",
    cranberry_long_drink_generic: "Cranberry long drink (generic)",
    wheat_beer_generic: "Wheat beer (generic)",
    red_wine_generic: "Red wine (generic)",
    white_wine_generic: "White wine (generic)",
    champagne_generic: "Champagne (generic)",
    karhu: "Karhu",
    sandels: "Sandels",
    karjala: "Karjala",
    a_le_coq: "A. Le Coq",
    kronenburg_blanc: "Kronenburg Blanc",
    koff: "Koff",
    olvi: "Olvi",
    aura: "Aura",
    somersby: "Somersby",
    happy_joe: "Happy Joe",
    fizz: "Fizz",
    upcider: "Upcider",
    crowmore: "Crowmore",
    hartwall_original: "Hartwall Original",
    hartwall_original_cranberry: "Hartwall Original Cranberry",
    a_le_coq_long_drink: "A. Le Coq Long Drink",

    open_counter: "Open counter",

    create_tag: "Create category",
    tag_name: "Name",
    view_tags: "View categories",
    tag_info: "Category info",
    all_tags: "All categories",
    editing_tag: "Editing category",
    tags: "Categories",
    product_counters: "Counters",
    no_category: "No category",
    no_counters: "No counters",
    
    copy_products_from: "Copy products from another counter",
    choose_products_to_copy: "Choose counter to copy products from",
    copy_products: "Copy products",
    
    tables: "Tables",
    table: "Table:",
    add_table: "Add table",
    no_tables: "No tables",
    enable_tables: "Enable tables",
    edit_table: "Edit table:",
    table_info: "Table info",
    edit: "Edit",
    table_code: "Table code",
    table_code_helper: "Table code is the number that customer sees on the table. Code must be between 1-1000",
    table_info_helper: "Table info is only visible to organisation members, 0-50 characters",
    no_table: "No table",
    choose: "Choose",
    choose_table: "Choose table",
    activate_table_service: "Activate table service",
    no_table_selected: "No table selected",

    closed: "Ordering service is closed, welcome again later",
    refresh: "Refresh",
    loading: "Loading...",
    error: "Error",
    loading_error: "Loading Error",

    settings: "Settings",
    change_password: "Change password",
    old_password: "Old password",
    new_password: "New password",
    save: "Save changes",
    settings_saved: "Settings saved",
    settings_not_saved: "Settings could not be saved",
    wrong_old_password: "Wrong old password",
    same_password: "New password cannot be the same as old password",
    continue: "Continue",
    wrong_password: "Wrong password",
    no_database_connection: "Cannot connect to Database",
    passwords_dont_match: "Passwords don't match",
    first_new_password: "New password",
    second_new_password: "New password again",
    to_shopping_cart: "To shopping cart",
    shopping_cart: "Shopping cart",
    shopping_cart_empty: "Shopping cart is empty",
    shopping_cart_total: "Total:",
    empty_shopping_cart: "Empty shopping cart",
    total_products: "Total products:",
    to_checkout: "To Checkout",
    checkout: "Checkout",
    items: "Items",
    delivery: "Delivery",
    to_table_nr: "To table nr:",
    self_fetch: "Pickup from counter",
    total: "Total",
    to_pay: "To pay",
    want_receipt: "I want a receipt",
    no_receipt: "I don't want a receipt",
    email_for_receipt: "Email for receipt",
    receipt: "Receipt",
    non_valid_email: "Email-address provided is not valid",
    empty_email: "Email is empty",
    notification_options: "Change notification settings",
    notification_plead_0: "Don't miss your order!",
    notification_plead_1: "Turn on and allow notifications",
    notification_plead_2: "Don't worry. We will only use notifications to inform you when your order is ready.",

    order_already_delivered: "Order has already been marked delivered",
    order_not_found: "Order not found",
    order_not_paid: "Order not paid",
    view_product_details: "View product details",
    shopping_list_empty: "Shopping list is empty",
    notification_options_title: "Notification options",
    browser_notification: "Enable browser notifications",
    skip_notification: "Skip",
    stop_notifications: "Stop notifications",
    notification_created: "Notification created",
    notifications_must_be_accepted: "Notifications must be accepted to be shown",
    something_went_wrong: "Something went wrong",

    thanks_for_order: "Thanks for your order!",
    drinks_are_ready: "Your drinks are ready!",
    ordered_drinks_soon_ready: "Your ordered drinks will be ready soon",
    order_code_is: "Your order code is:",
    add_phone: "Add your phone number",
    confirm: "Confirm",
    cancel: "Cancel",
    logout: "Logout",
    password_reasoning: "First input your password to edit your information",
    queue_status: "Status of queue",
    ready: "Ready!",
    slide_to_mark_received: "Slide to mark received",
    history: "Order History",
    new_orders: "New Orders",
    fetchable: "Fetchable",
    variants: "Variants (eg. Drink size or Gin brand in GT)",
    variants_short: "Variants",
    variant: "Variant's title",
    variants_listing: "Variants",
    no_variants: "No variants",
    options: "Options",
    option_name: "Option name",
    description: "Description (optional)",
    calculated_automatically: "Calculated automatically",
    price_difference: "Price difference (eg. -0.5)",
    closing_times: "Automatic closing times",
    closing_times_desc: 'Input time with 5 minutes of accuracy in format hh:mm, system takes into account day change, for example 23:00 closes counter at 23:00 and 01:00 closes counter at 01:00 the next day (day "changes" at 10:00). You can also leave the field empty, in which case counter will not close automatically.',
    additional_info: "Additional information",
    mon: "mon",
    tue: "tue",
    wed: "wed",
    thu: "thu",
    fri: "fri",
    sat: "sat",
    sun: "sun",
    invalid_time_format: "!",

    invalid_user_input: "Invalid user input",
    internal_server_error: "Internal server error",
    back_to_all_categories: "Back to all categories",
    counter_closed: "Counter closed",
    back: "Back",
    size: "Size",
    alcohol_content: "Alcohol content",
    tax: "VAT %",
    creating_charge_failed: "Creating payment failed",
    payment_failed: "Payment failed",
    payment_update_error: "Transaction status could not be updated. Please contact support.",
    payment_maintenance_break: "Payment failed due to maintenance break",
    creating_charge_failed: "Could not connect to payment service",
    unknown_error: "Unknown error",
    last_call: "Last call",
    five_min_accuracy: "Closing time edited to 5 minute accuracy",
    change_visibility: "Change product visibility",
    orders: "Orders",
    notification_package_object: {
      order_title: "Your order is ready!",
      order_body_0: "Your order ",
      order_body_1: " is ready for pickup.",
      order_body_delivery: "is on its way!"
    },
    search: "Search",
    reorganize: "Reorganize",
    can_also_dragdrop: "You can also drag and drop products to reorder",
    can_also_dragdrop_tags: "You can drag and drop categories to reorder",
    show_all: "Show all",
    different_browser_warning: "Attention! Our system flagged this order as someone elses. Either the order was made using a different browser or using a different device. Please discuss with the bartender if you feel like there has been an error.",
    day: "Day",
    time: "time",
    refund: "Refund",
    refunded: "Refunded",

  }
};

const useTranslate = () => {
  const languageContext = useLanguageContext();
  const lang = languageContext ? languageContext.language : "fi";

  return (str) => {
    if (!str) {
      return null;
    }

    if (str.match(/Path `.*` is required/)) {
      return translate("missing_fields");
    }

    if(str.split(" ").length > 1) {
      return str.split(" ").map(s => dictionary[lang][s]).join(" ");
    }

    if (str in dictionary[lang]) {
      return dictionary[lang][str];
    } else {
      return "Missing translation " + str;
    }
  }
}

export default useTranslate;